// Imports: Dependencies
import * as React from 'react';

// Page: Not Found
const NotFoundPage = () => {
  return (
    <div id="not-found-container">
    <h1 id="not-found-title">404</h1>

    <div id="not-found-subtitle">
      <p>Page not found.</p>
      <p>Congrats, you beat the system.</p>
    </div>
  </div>
  );
};

// Exports
export default NotFoundPage;
